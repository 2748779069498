import dynamic from "next/dynamic";

// Full Size Components (Modules)
const HeroModule = dynamic(() =>
  import("../components/Shared/HeroModule/HeroModule")
);
const Hero2Module = dynamic(() =>
  import("../components/Shared/Hero2Module/Hero2Module")
);
const Hero3Module = dynamic(() =>
  import("../components/Shared/Hero3Module/Hero3Module")
);
const Hero4Module = dynamic(() =>
  import("../components/Shared/Hero4Module/Hero4Module")
);
const Hero5Module = dynamic(() =>
  import("../components/Shared/Hero5Module/Hero5Module")
);
const Hero7Module = dynamic(() =>
  import("../components/Shared/Hero7Module/Hero7Module")
);

const ThreeStepsModule = dynamic(() =>
  import("../components/Shared/ThreeStepsModule/ThreeStepsModule")
);
const TwoBoxes = dynamic(() =>
  import("../components/Shared/TwoBoxes/TwoBoxes")
);
const TwoBoxes2 = dynamic(() =>
  import("../components/Shared/TwoBoxes2/TwoBoxes2")
);
const ThreeBoxes2 = dynamic(() =>
  import("../components/Shared/ThreeBoxes2/ThreeBoxes2")
);
const CallToActionModule = dynamic(() =>
  import("../components/Shared/CallToActionModule/CallToActionModule")
);
const ImageModule = dynamic(() =>
  import("../components/Shared/ImageModule/ImageModule")
);
const ImageExtraMargin = dynamic(() =>
  import("../components/Shared/ImageExtraMargin/ImageExtraMargin")
);
const BioModule = dynamic(() =>
  import("../components/Shared/BioModule/BioModule")
);

const ContactModule = dynamic(() =>
  import("../components/Shared/ContactModule/ContactModule")
);
const ConfirmationModule = dynamic(() =>
  import("../components/Shared/ConfirmationModule/ConfirmationModule")
);
const Custom404Module = dynamic(() =>
  import("../components/Shared/Custom404Module/Custom404Module")
);

const HeadlineHeadline = dynamic(() =>
  import("../components/Shared/HeadlineHeadline/HeadlineHeadline")
);
const MissionCopy = dynamic(() =>
  import("../components/Shared/MissionCopy/MissionCopy")
);

const SignUp = dynamic(() => import("../components/Shared/SignUp/SignUp"));

const FullWidthText = dynamic(() =>
  import("../components/Shared/FullWidthText/FullWidthText")
);

const JobPosting = dynamic(() =>
  import("../components/JobPosting/JobPosting.js")
);

// Dealer Pages
const DealerHero = dynamic(() =>
  import("../components/Shared/DealerHero/DealerHero")
);
const DealerHowItWorksHero = dynamic(() =>
  import("../components/Shared/DealerHowItWorksHero/DealerHowItWorksHero")
);
const DealerHowItWorksForm = dynamic(() =>
  import("../components/Shared/DealerHowItWorksForm/DealerHowItWorksForm")
);
const DealerTwoBoxesImagesRight = dynamic(() =>
  import(
    "../components/Shared/DealerTwoBoxesImagesRight/DealerTwoBoxesImagesRight"
  )
);
const DealerTwoBoxesImagesLeft = dynamic(() =>
  import(
    "../components/Shared/DealerTwoBoxesImagesLeft/DealerTwoBoxesImagesLeft"
  )
);
const DealerIconBulletsImages = dynamic(() =>
  import("../components/Shared/DealerIconBulletsImages/DealerIconBulletsImages")
);
const DealerCallToActionModule = dynamic(() =>
  import(
    "../components/Shared/DealerCallToActionModule/DealerCallToActionModule"
  )
);
const DealerThreeIconsCopy = dynamic(() =>
  import("../components/Shared/DealerThreeIconsCopy/DealerThreeIconsCopy")
);
const DealerConfirmationModule = dynamic(() =>
  import(
    "../components/Shared/DealerConfirmationModule/DealerConfirmationModule"
  )
);

// Home
const HomeProgramDescription = dynamic(() =>
  import("../components/Home/HomeProgramDescription/HomeProgramDescription")
);
const HomeReservationExplainer = dynamic(() =>
  import("../components/Home/HomeReservationExplainer/HomeReservationExplainer")
);
const WhyASubscription = dynamic(() =>
  import("../components/Home/WhyASubscription/WhyASubscription")
);
const AllInSection = dynamic(() =>
  import("../components/Home/AllInSection/AllInSection")
);
const MediaSection = dynamic(() =>
  import("../components/Home/MediaSection/MediaSection")
);
const ArrivalSection = dynamic(() =>
  import("../components/Home/ArrivalSection/ArrivalSection")
);

// Landing Pages
const Masthead3 = dynamic(() =>
  import("../components/LandingPages/Masthead3/Masthead3")
);
const PaymentCalculator = dynamic(() =>
  import("../components/LandingPages/PaymentCalculator/PaymentCalculator")
);
const ModelYSubScription = dynamic(() =>
  import("../components/LandingPages/ModelYSubScription/ModelYSubScription")
);
const PressFeatures = dynamic(() =>
  import("../components/LandingPages/PressFeatures/PressFeatures")
);
const PressFeatures2 = dynamic(() =>
  import("../components/LandingPages/PressFeatures2/PressFeatures2")
);
const ValueProp = dynamic(() =>
  import("../components/LandingPages/ValueProp/ValueProp")
);
const SubscribeAndSave = dynamic(() =>
  import("../components/LandingPages/SubscribeAndSave/SubscribeAndSave")
);
const PricingTable = dynamic(() =>
  import("../components/LandingPages/PricingTable/PricingTable")
);
const Video = dynamic(() => import("../components/Shared/Video/Video"));

// Blog
const BlogContent = dynamic(() =>
  import("/components/Blog/components/BlogContent/BlogContent")
);
const BlogHero = dynamic(() =>
  import("/components/Blog/components/BlogHero/BlogHero")
);

const GreenFuture = dynamic(() =>
  import("../components/Shared/GreenFuture/GreenFuture")
);

export default function buildComponentStructure(
  content,
  carouselContent,
  subnavContent,
  pageData = {}
) {
  // The following function allows the Butter user to rearrange content within the schema.
  // The React app will build the Homepage in the order it receives from Butter's data.body parameters.

  // Make blog meta data available if it exists
  const blogMeta =
    content && content?.fields?.blog_meta ? content.fields.blog_meta : null;

  const pageBody = content?.fields?.body?.map((component, i) => {
    // Get the Component Type and its Fields of this item in the loop
    let type = component.type;
    let fields = component.fields;

    // Render Components associated with the Component Type
    if (type === "arrival_section")
      return <ArrivalSection fields={fields} key={i} />;
    else if (type === "hero_module")
      return <HeroModule fields={fields} key={i} pageData={pageData} />;
    else if (type === "hero2_module")
      return <Hero2Module fields={fields} key={i} />;
    else if (type === "hero3_module")
      return (
        <Hero3Module fields={fields} key={i} subnavContent={subnavContent} />
      );
    else if (type === "hero4_module")
      return (
        <Hero4Module fields={fields} key={i} subnavContent={subnavContent} />
      );
    else if (type === "hero5_module")
      return (
        <Hero5Module fields={fields} key={i} subnavContent={subnavContent} />
      );
    else if (type === "hero7_module")
      return (
        <Hero7Module fields={fields} key={i} subnavContent={subnavContent} />
      );
    else if (type === "home_program_description")
      return <HomeProgramDescription fields={fields} key={i} />;
    else if (type === "reservation_explainer")
      return <HomeReservationExplainer fields={fields} key={i} />;
    else if (type === "why_a_subscription")
      return <WhyASubscription fields={fields} key={i} />;
    else if (type === "all_in_section")
      return <AllInSection fields={fields} key={i} />;
    else if (type === "media_section")
      return <MediaSection fields={fields} key={i} />;
    else if (type === "headline_headline")
      return <HeadlineHeadline fields={fields} key={i} />;
    else if (type === "mission_copy")
      return <MissionCopy fields={fields} key={i} />;
    else if (type === "tesla_comparison_table") return null;
    else if (type === "three_steps_module")
      return <ThreeStepsModule fields={fields} key={i} />;
    else if (type === "two_boxes") return <TwoBoxes fields={fields} key={i} />;
    else if (type === "two_boxes2")
      return <TwoBoxes2 fields={fields} key={i} />;
    else if (type === "three_boxes2")
      return <ThreeBoxes2 fields={fields} key={i} />;
    else if (type === "image") return <ImageModule fields={fields} key={i} />;
    else if (type === "image_extra_margin")
      return <ImageExtraMargin fields={fields} key={i} />;
    else if (type === "bio_module")
      return <BioModule fields={fields} key={i} />;
    else if (type === "contact_module")
      return <ContactModule fields={fields} key={i} />;
    else if (type === "confirmation")
      return <ConfirmationModule fields={fields} key={i} />;
    else if (type === "404_module")
      return <Custom404Module fields={fields} key={i} />;
    else if (type === "call_to_action_module")
      return <CallToActionModule fields={fields} key={i} />;
    else if (type === "sign_up") return <SignUp fields={fields} key={i} />;
    else if (type === "full_width_text")
      return <FullWidthText fields={fields} key={i} />;
    else if (type === "job_posting")
      return <JobPosting fields={fields} key={i} />;
    else if (type === "dealer_hero")
      return <DealerHero fields={fields} key={i} />;
    else if (type === "dealer_how_it_works_hero")
      return <DealerHowItWorksHero fields={fields} key={i} />;
    else if (type === "dealer_how_it_works_form")
      return <DealerHowItWorksForm fields={fields} key={i} />;
    else if (type === "dealer_two_boxes_images_right")
      return <DealerTwoBoxesImagesRight fields={fields} key={i} />;
    else if (type === "dealer_two_boxes_images_left")
      return <DealerTwoBoxesImagesLeft fields={fields} key={i} />;
    else if (type === "dealer_icon_bullets_images")
      return <DealerIconBulletsImages fields={fields} key={i} />;
    else if (type === "dealer_call_to_action_module")
      return <DealerCallToActionModule fields={fields} key={i} />;
    else if (type === "dealer_three_icons_copy")
      return <DealerThreeIconsCopy fields={fields} key={i} />;
    else if (type === "dealer_confirmation_module")
      return <DealerConfirmationModule fields={fields} key={i} />;
    else if (type === "masthead_3")
      return <Masthead3 fields={fields} key={i} />;
    else if (type === "subscribe_and_save")
      return <SubscribeAndSave fields={fields} key={i} />;
    else if (type === "model_y_subscription")
      return <ModelYSubScription fields={fields} key={i} />;
    else if (type === "payment_calculator")
      return <PaymentCalculator fields={fields} key={i} />;
    else if (type === "press_features")
      return <PressFeatures fields={fields} key={i} />;
    else if (type === "press_features_2")
      return <PressFeatures2 fields={fields} key={i} />;
    else if (type === "value_prop")
      return <ValueProp fields={fields} key={i} />;
    else if (type === "video") return <Video fields={fields} key={i} />;
    else if (type === "blog_content")
      return <BlogContent fields={fields} key={i} />;
    else if (type === "blog_hero")
      return <BlogHero fields={fields} meta={blogMeta} key={i} />;
    else if (type === "green_future")
      return <GreenFuture field={fields} key={i} />;
    else if (type === "pricing_table")
      return <PricingTable fields={fields} key={i} />;
  });

  return pageBody;
}
